import React from "react";

const Message = () => {
  return <div>
    <h2 style={{marginTop :"10%"}}>Sorry, but it looks like this link has already been used!</h2>

  </div>;
};

export default Message;
