import "./count.css";

import React, { useEffect, useState } from "react";
import "./count.css";

const CountdownTimer = () => {
  //   const [secValue, setSecValue] = useState(11);
  //   const [minValue, setMinValue] = useState(2);
  //   const [hourValue, setHourValue] = useState(2);
  //   const [dayValue, setDayValue] = useState(9);

  const targetDate = new Date("2023-09-03T00:00:00"); // Replace with your target date
  const currentDate = new Date();

  const timeDifference = targetDate - currentDate;

  const secondsInMilli = 1000;
  const minutesInMilli = secondsInMilli * 60;
  const hoursInMilli = minutesInMilli * 60;
  const daysInMilli = hoursInMilli * 24;

  const days = Math.floor(timeDifference / daysInMilli);
  const hours = Math.floor((timeDifference % daysInMilli) / hoursInMilli);
  const minutes = Math.floor((timeDifference % hoursInMilli) / minutesInMilli);
  const seconds = Math.floor(
    (timeDifference % minutesInMilli) / secondsInMilli
  );

  const [secValue, setSecValue] = useState(seconds);
  const [minValue, setMinValue] = useState(minutes);
  const [hourValue, setHourValue] = useState(hours);
  const [dayValue, setDayValue] = useState(days);

  useEffect(() => {
    const timeFunction = setInterval(() => {
      setSecValue((prevSecValue) => {
        if (prevSecValue === 0) {
          setMinValue((prevMinValue) => {
            if (prevMinValue === 0) {
              setHourValue((prevHourValue) => {
                if (prevHourValue === 0) {
                  setDayValue((prevDayValue) => {
                    if (prevDayValue === 0) {
                      clearInterval(timeFunction);
                      return prevDayValue;
                    }
                    return prevDayValue - 1;
                  });
                  return 24;
                }
                return prevHourValue - 1;
              });
              return 60;
            }
            return prevMinValue - 1;
          });
          return 60;
        }
        return prevSecValue - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timeFunction);
    };
  }, []);

  const formatValue = (value) => (value < 10 ? `0${value}` : value);

  return (
    <div className="containerTime">
      <div className="time-content">
        <div className="time days">
          <span className="number">{formatValue(dayValue)}</span>
          <span className="text">DAYS</span>
        </div>
        <div className="time hours">
          <span className="number">{formatValue(hourValue)}</span>
          <span className="text">HOURS</span>
        </div>
        <div className="time minutes">
          <span className="number">{formatValue(minValue)}</span>
          <span className="text">MINUTES</span>
        </div>
        <div className="time seconds">
          <span className="number">{formatValue(secValue)}</span>
          <span className="text">SECONDS</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
