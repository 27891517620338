import "./App.css";
import HomePage from "./pages/home/home.js";
// import back from "./img/v.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <HomePage />
      {/* <img src={back} alt="Background" /> */}

    </div>
  );
}

export default App;
