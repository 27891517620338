import React from "react";

const Done = ({ msg }) => {
  return (
    <div>
      <h1>Thanks</h1>
      <h4>{msg}</h4>
    </div>
  );
};

export default Done;
